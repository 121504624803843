@media only screen and (max-width: 500px) {

  #root-dengendonor {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
  }

  #dengendonor {
    height: 100%;
    #header-dengendonor {
      header {
        .MuiToolbar-gutters {
          padding: 0;
        }
        .MuiIconButton-root {
          padding: 0 10px 0 0;
        }
        .title {
          font-size: 12px;
          text-overflow: clip;
        }
        .img-logo {
          height: 40px;
          margin-left: 3px;
        }
        .icon-menu {
          .img-title {
            margin-top: -6px;
          }
        }
      }
    }

  }
  #container-dengendonor {
    height: calc(100% - 58px - 63px) !important;
    padding: 0;

    .MuiContainer-root {
      padding: 0 !important;
    }
    .menu-content {
      margin-top: 0 !important;
      .menu-content-item {
        margin-top: 0 !important;
        .line-content {
          margin: 0 auto !important;
        }
        .menu-content-item-detail {
          position: fixed;
          max-height: calc(100% - 211px) !important;
          margin-right: 15px !important;
          overflow: auto;
        }
      }
    }
  }
  #dengendonor #footer-dengendonor {
    height: 63px !important;
    padding: 0;
    .footer-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 63px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    #header-dengendonor {
      height: 10%;
      header {
        .title {
          font-size: 10px !important;
        }
        .img-logo img {
          width: 120px;
          padding-top: 3px;
        }
      }
    }

    .gm-style .gm-style-iw-c {
      max-width: 300px !important;
      min-width: 300px !important;
    }
    .dialog-show-detail-location .MuiPaper-root {
      min-width: 300px !important;
    }
  }

  .map-swal2-container {
    .swal2-content {
      padding: 0 0.5rem !important;
    }
  }
}