.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.pb-4px {
  padding-bottom: 4px !important;
}
.p-5px {
  padding: 5px !important;
}
.pl-10px {
  padding-left: 10px !important;
}
.pr-10px {
  padding-right: 10px !important;
}
.pl-9px {
  padding-left: 9px !important;
}
.pr-20px {
  padding-right: 20px !important;
}
.pt-20px{
  padding-top: 20px !important;
}

.mt-10px {
  margin-top: 10px !important;
}
.mt-12px {
  margin-top: 12px !important;
}
.d-inline-grid {
  display: inline-grid !important
}
.text-center {
  text-align: center;
}

.font-size-12px {
  font-size: 12px;
}
.font-size-13px {
  font-size: 13px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-16px {
  font-size: 16px;
}
.font-size-18px {
  font-size: 18px;
}
.h-17px {
  height: 17px !important;
}
.display-none { display: none !important;}
.text-left {
  text-align: left !important;
}
.text-link {
  text-decoration: none;
  color: #4285F4 !important;
  &:hover {
    text-decoration: underline;
  }
}

.line-clamp-1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}
.line-clamp-2 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  max-height: 36px !important;
  line-height: 20px;
}
.white-space-pre-line{
  white-space: pre-line !important;
}