$fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
body {
	margin: 0;
	font-family:$fontFamily;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#dengendonor{
	color: #414141;

	#header-dengendonor {
		min-height: 58px;
		max-height: 58px;
		header {
			position: absolute;
			min-height: 58px;
			max-height: 58px;
			.MuiToolbar-gutters {
				min-height: 58px;
				max-height: 58px;
			}
			border-bottom: 2px solid black;
			box-shadow: 0 2px 0 #EA9900;
		}
		.MuiIconButton-root{
			font-size: xx-small;
			border-radius: 0;
			&:hover {
				background-color: transparent;
			}
		}
		.appBar {
			color: black;
			background-color: white;
		}
		.title {
			flex-grow: 1;
			color: black;
			font-size: 16px;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
		}
		.MuiAppBar-colorPrimary {
			background-color: white;
		}
		.img-logo {
			max-height: 40px !important;
		}
		.icon-menu {
			img {
				margin-right: -20px;
			}
			svg {
				color: black;
			}
			.img-title {
				color: black;
			}
			p {
				font-size: 12px;
				padding: 0;
				margin: 0;
			}
			.MuiTouchRipple-root {
				display:  none !important;
			}

		}
	}
	#container-dengendonor {
		height: calc(100vh - 58px - 63px);
		display: flex;
		position: relative;
		font-weight: normal;
		.MuiContainer-root {
			position: relative;
			padding: 0 65px;
			margin: 0 auto;
			max-width: 100%;
		}

		.menu-content {
			padding: 15px;
			p {
				font-size: 18px;
				padding: 5px;
				color: black;
			}
			.menu-content-item {
				margin-top: 6px;
				.title {
					padding: 5px 0 15px 0;
					font-size: 18px;
					svg {
						float: right;
						vertical-align: bottom;
					}
				}
				p {
					font-size: 15px;
					line-height: 24px;
					padding: 4px 16px;
					margin: 0;
				}
				.menu-content-item-detail {
					display: block;
					max-height: calc(100vh - 250px);
					overflow: auto;
				}
				.line-content {
					width: 100%;
					height: 1px;
					background: black;
					max-width: 500px;
					margin: 10px auto;
				}
			}
			.line {
				width: 100%;
				height: 1px;
				background: black;
			}
		}
		.menu-footer {
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			padding-bottom: 1rem;

			.menu-footer-1 {
				width: 100%;
				text-align: center;
				padding: 10px 0;
				line-height: 25px;

				.menu-footer-1-content {
					text-align: left !important;
					display: inline-block;
				}
			}
			.menu-footer-2 {
				width: 100%;
				text-align: center;
				font-size: 12px;
				color: #8D8D8D;
			}
		}
	}

	#footer-dengendonor {
		//height: 8vh;
		height: 63px;
		width: 100%;
		display: block;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		padding: 0 65px;

		.footer-content {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			img {
				cursor: pointer;
				height: 100%;
				animation: rendered 1s ease;
				animation-iteration-count: 1;
				@keyframes rendered {
					0% {
						opacity: 0;
					}
					100% {
						opacity: 1;
					}
				}
			}
		}
	}
	@media (min-width: 600px) {
		.MuiToolbar-gutters {
			padding-left: 58px;
			padding-right: 58px;
		}
	}
}

.custom-swal-fire2 {
	.swal2-actions {
		display: none !important;
	}
	.swal2-popup {
		padding: 8px;
		.swal2-content {
			padding: 0;
			font-size: 16px;
			.header {
				display: flex;
				line-height: 25px;
				color: black;
				font-weight: normal;
				.title {
					width: 100%;
					font-weight: bold;
				}
				.icon {
					position: absolute;
					right: 8px;
					img {
						width: 24px;
					}
					span {
						font-size: 11px;
						display: block;
						margin-top: -5px;
					}
				}
			}
			.body {
				color: black;
				font-weight: normal;
				.title {
					width: 100%;
					font-size: 17px;
					display: flex;
					justify-content: center;
					flex-direction: row;
					font-weight: bold;
					.icon {
						margin: -4px 5px 0;
					}
				}
				.content {
					font-size: 14px;
					font-weight: 300;
					padding: 4px 0;
					.btn-direction {
						display: flex !important;
						justify-content: center !important;
					}
				}
			}
			hr {
				margin: 8px 0;

			}
		}
	}
}

.info-window {
	padding: 10px 20px !important;
	text-align: center;
	font-size: 16px;
	.header {
		display: flex;
		line-height: 25px;
		color: black;
		font-weight: normal;
		min-height: 50px;
		.title {
			width: 100%;
			font-weight: bold;
			font-family: $fontFamily;
			span {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				display: inherit;
				margin: 0 18px;
				height: 25px;
			}
		}
		.icon {
			position: absolute;
			right: 10px;
			img {
				width: 24px;
			}
			span {
				font-size: 11px;
				display: block;
				margin-top: -5px;
			}
		}
	}
	.body {
		color: black;
		font-weight: normal;
		.title {
			width: 100%;
			font-size: 17px;
			display: flex;
			justify-content: center;
			flex-direction: row;
			font-weight: bold;
			padding-bottom: 4px;
			padding-top: 4px;
			font-family: $fontFamily;
			.icon {
				margin: -4px 5px 0;
			}
		}
		.content {
			font-family: Roboto, Arial, sans-serif; ;
			font-size: 14px;
			font-weight: 300;
			padding: 4px 0;
		}
		#line-clamp-2 {
			overflow: hidden !important;
			max-height: 36px !important;
		}
	}
	hr {
		margin: 8px 0 !important;
	}
}

.dialog-show-detail-location {
	.MuiPaper-root {
		padding:  0 !important;
		margin: 20px !important;
		min-width: 350px;
		max-width: 350px;
	}
	.info-window {
		.header{
			.title {
				span {
					margin: 0 10px;
				}
			}
			.icon {
				right: 5px;
				top: 5px;
			}
		}
	}
	.content {
		padding: 4px 0;
		line-height: 24px;
		.btn {
			font-size: 12px;
			font-weight: 600;
			display: flex !important;
			justify-content: center !important;
			cursor: pointer;
		}

		.direction {
			max-width: 160px;
			margin: 0 auto;
			border: 1px #4285F4 solid;
			padding: 6px 12px;
			border-radius: 25px;
			line-height: 18px;
		}
		.youtube {
			margin: 0 auto;
			.text {
				line-height: 42px;
			}
		}
	}
}

.gm-style {
	div {
		border: 0 !important;
		&:focus {
			outline: none !important;
		}
	}
	.gm-style-iw-c {
		padding: 0 !important;
		margin: 0 !important;
		max-width: 300px !important;
	}
	.gm-svpc {
		display: none !important;
	}

	.gm-fullscreen-control {
		display: none !important;
	}

	.gm-ui-hover-effect {
		display: none !important;
	}

	.gm-ui-hover-effect {
		display: none !important;
	}
	.gm-style-iw-d {
		overflow: inherit !important;
		min-width: 300px !important;
	}
}

.my-location {
	cursor: pointer;
	text-align: center;
	width: 40px;
	height: 40px;
	float: right;
	bottom: 150px;
	position: relative;
	color: black;
	background: white;
	right: 10px;
	font-size: 14px;
	font-weight: bold;
	border-radius: 50%;
	.text {
		background-image: url("../img/my_location.png");
		height: 18px;
		width: 18px;
		margin: 11px;
		background-position-x: 90px;
	}
}
.gmnoprint {
	.gm-style-mtc {
		button {
			font-size: 16px !important;
			padding: 0 7px !important;
			height: 30px !important;
			outline:none!important
		}
		ul li {
			font-size: 16px !important;
			padding: 5px !important;
			outline:none!important
		}
	}
}

.map-swal2-container {
	.swal2-close {
		color: black;
		font-size: 28px;
		box-shadow: none !important;
	}
	.swal2-content {
		color: black;
		font-size: 16px;
		line-height: 25px;
	}
}